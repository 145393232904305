import React, { FC, useEffect, useState, useRef } from 'react'
import ReactLoading from 'react-loading'
import { useParams, useHistory } from 'react-router-dom'
import { useApi } from 'hooks/useApi'
import { uniqWith } from 'lodash'
import Treatment from 'models/Treatment'
import TreatmentSidebar from './components/TreatmentSidebar'
import Header from 'components/Header'
import { useSurveys } from 'hooks/useSurveys'
import Session from 'models/Session'
import ScheduledTreatment from './components/ScheduledTreatment'
import NonScheduledTreatment from './components/NonScheduledTreatment'
import SessionCreatorModal from './components/SessionCreatorModal'
import './newTreatment.scss'
import { useToast } from 'hooks/useToast'
import SessionImporterModal from './components/SessionImporterModal'

export type TreatmentType = 'scheduled' | 'normal'
export type TreatmentUpdateObject = {
  id?: number
  name: string
  description: string
  survey_id?: number
  pre_survey_id?: number
  post_survey_id?: number
}
export type TreatmentStep = {
  step?: number
  sessions: Session[]
}

const TreatmentManager: FC = () => {
  const { id } = useParams()
  const [
    updatedTreatment,
    setUpdatedTreatment,
  ] = useState<TreatmentUpdateObject>({
    name: null,
    description: null,
  })
  const [sessions, setSessions] = useState<TreatmentStep[]>([
    { step: 1, sessions: [] },
  ])
  const deletedSessions = useRef<Session[]>([])
  const [treatmentType, setTreatmentType] = useState<TreatmentType>('scheduled')
  const [isSessionCreatorVisible, setSessionCreatorVisible] = useState(false)
  const [isCalendarView, setCalendarView] = useState(false)
  const [isSessionImporterVisible, setSessionImporterVisible] = useState(false)
  const [editSession, setEditSession] = useState<Session>()
  const [step, setStep] = useState(0)
  const { surveys, fetchSurveys, loading: loadingSurveys } = useSurveys()
  const { showToast } = useToast()
  const history = useHistory()
  const [getTreatmentRequest, { loading: loadingTreatment }] = useApi(
    'GET',
    `/doctor/treatment/${id}`
  )
  const [
    getSessionsRequest,
    { loading: loadingSessions, data: allDoctorSessions },
  ] = useApi<Session[]>('GET', `/doctor/sessions`)
  const [saveNewTreatmentRequest, { loading: savingNewTreatment }] = useApi(
    'POST',
    `/treatment`
  )
  const [updateTreatmentRequest, { loading: updatingTreatment }] = useApi(
    'PUT',
    `/treatment/${id}`
  )

  const onUpdateTreatment = async () => {
    if (!updatedTreatment.name || !updatedTreatment.description) {
      showToast({
        name: 'Error',
        value: 'Treatment name and description are required fields.',
      })
      return
    }

    const res = await updateTreatmentRequest({
      body: {
        ...updatedTreatment,
        sessions: getUpdatedSessionsArray(),
      },
    })
    if (res?.data?.success) {
      showToast({
        name: 'Success',
        value: 'Treatment was successfully updated',
      })
      history.push('/dashboard/treatments')
    }
  }
  const onSaveNewTreatment = async () => {
    if (!updatedTreatment.name || !updatedTreatment.description) {
      showToast({
        name: 'Error',
        value: 'Treatment name and description are required fields.',
      })
      return
    }

    const updatedSessions = getUpdatedSessionsArray()
    if (updatedSessions.length === 0) {
      showToast({
        name: 'Error',
        value: 'Cannot create a treatment without sessions.',
      })
      return
    }

    const res = await saveNewTreatmentRequest({
      body: {
        ...updatedTreatment,
        sessions: updatedSessions,
      },
    })
    if (res?.data?.success) {
      showToast({
        name: 'Success',
        value: 'Treatment was successfully created',
      })
      history.push('/dashboard/treatments')
    }
  }

  const getUpdatedSessionsArray = (): Session[] => {
    let result: Session[] = []
    sessions.forEach(({ sessions }) => {
      result = result.concat(sessions)
    })
    result = result.filter((s) => s.updated)
    result = uniqWith(
      result,
      (a: Session, b: Session) =>
        (a.title === b.title && a.session_type_id === b.session_type_id) ||
        (a.id && b.id && a.id === b.id)
    )
    result = result.map((session) => {
      const s = { ...session }
      delete s.updated
      if (s.session_type_id === 3) {
        delete s.title
        delete s.survey
        if (s.id) {
          delete s.survey_id
        }
      }
      if (treatmentType === 'normal') {
        delete s.available_on_day
      }
      if (s.isExisting) {
        delete s.title
        delete s.session_type_id
      }
      return s
    })
    result = result.concat(deletedSessions.current)
    return result
  }

  const loadTreatment = async () => {
    if (id) {
      const res = await getTreatmentRequest()
      const fetchedTreatment: Treatment = res.data
      setUpdatedTreatment({
        name: fetchedTreatment.name,
        description: fetchedTreatment.description,
        pre_survey_id: fetchedTreatment.preSurvey?.survey_id,
        post_survey_id: fetchedTreatment.postSurvey?.survey_id,
        survey_id: fetchedTreatment.dailySurvey?.survey_id,
      })
      if (!fetchedTreatment.sessions[0]?.available_on_day) {
        setSessions([
          {
            step: 1,
            sessions: fetchedTreatment.sessions.sort(
              (a, b) => a.order_number[0] - b.order_number[0]
            ),
          },
        ])
        setTreatmentType('normal')
      } else {
        let treatmentSessionDays = []
        fetchedTreatment.sessions.forEach((s) => {
          treatmentSessionDays = treatmentSessionDays.concat(s.available_on_day)
        })
        const totalSteps = Math.max(...treatmentSessionDays, 0)
        const steps = []
        for (let i = 1; i <= totalSteps; i++) {
          const daySessions = fetchedTreatment.sessions
            .filter((s) => s.available_on_day.includes(i))
            .sort(
              (a, b) =>
                a.order_number[a.available_on_day.indexOf(i)] -
                b.order_number[b.available_on_day.indexOf(i)]
            )
            .map((s) => {
              const session = { ...s }
              if (session.session_type_id === 3 && !session.survey_id) {
                session.survey_id = session.survey.survey_id
              }
              if (!session.title) {
                session.title = getSessionTitle(session)
              }
              return session
            })
          steps.push({ step: i, sessions: daySessions })
        }
        setSessions(steps)
      }
    }
  }

  const onDeleteSession = (session: Session, step: number) => {
    const updatedSession = { ...session }
    if (treatmentType === 'scheduled') {
      const deleteIndex = session.available_on_day.indexOf(step)
      updatedSession.available_on_day.splice(deleteIndex, 1)
      updatedSession.order_number.splice(deleteIndex, 1)
      updatedSession.updated = true

      if (
        updatedSession.available_on_day.length === 0 &&
        session.id &&
        !session.isExisting
      ) {
        deletedSessions.current.push({ id: session.id })
      }
    } else if (session.id && !session.isExisting) {
      deletedSessions.current.push({ id: session.id })
    }

    setSessions(
      sessions.map((day) => {
        if (day.step === step) {
          return {
            step,
            sessions: day.sessions
              .filter((s) => s.title !== session.title)
              .map((s, i) => {
                const session = { ...s }
                const z =
                  treatmentType === 'scheduled'
                    ? session.available_on_day.indexOf(step)
                    : 0
                session.order_number[z] = i + 1
                session.updated = true
                return session
              }),
          }
        }
        if (updatedSession.available_on_day.includes(day.step)) {
          const index = day.sessions.findIndex(
            (s) => s.title === updatedSession.title
          )
          day.sessions[index] = updatedSession
        }
        return day
      })
    )
  }

  const onEditSession = (session: Session) => {
    if (treatmentType === 'normal') {
      setEditSession({ ...session, available_on_day: [1] })
    } else {
      setEditSession(session)
    }
    setSessionCreatorVisible(true)
  }

  const importSession = (step: number) => {
    setStep(step)
    setSessionImporterVisible(true)
  }

  const createSession = (step: number) => {
    setStep(step)
    setSessionCreatorVisible(true)
  }

  const addStep = (step, addStep) => {
    const newState = [...sessions]

    if (addStep) {
      for (let i = newState.length - 1; i >= step; i--) {
        newState[i].step = i + 2
      }
      newState.splice(step, 0, { step: step + 1, sessions: [] })
    } else {
      for (let i = step; i < newState.length; i++) {
        newState[i].step = i
      }
      newState.splice(step, 1)
    }

    setSessions(newState)
  }

  const onSessionImported = (session: Session) => {
    let alreadyImported = false
    sessions.forEach(({ sessions }) => {
      if (sessions.some(({ id }) => id === session.id)) {
        alreadyImported = true
      }
    })
    if (alreadyImported) {
      showToast({
        name: 'Error',
        value:
          'Session already imported. Please edit it if you want to add it to other steps.',
      })
      return
    }

    setSessionImporterVisible(false)
    const newSessions = [...sessions]
    const newSession = {
      ...session,
      title: getSessionTitle(session),
      order_number: [],
      updated: true,
    }
    session.available_on_day.forEach((step) => {
      newSession.order_number.push(
        sessions.find((s) => s.step === step).sessions.length + 1
      )
    })
    session.available_on_day.forEach((step) => {
      newSessions.find((s) => s.step === step).sessions.push(newSession)
    })
    setSessions(newSessions)
  }

  const onSessionCreated = (session: Session) => {
    const newSessions = [...sessions]
    const newSession = {
      ...session,
      order_number: [],
      updated: true,
    }
    if (!newSession.title) {
      newSession.title = getSessionTitle(newSession)
    }
    session.available_on_day.forEach((step) => {
      newSession.order_number.push(
        sessions.find((s) => s.step === step).sessions.length + 1
      )
    })
    session.available_on_day.forEach((step) => {
      newSessions.find((s) => s.step === step).sessions.push(newSession)
    })
    setSessions(newSessions)
    setSessionCreatorVisible(false)
  }

  const onSessionEdited = (session: Session) => {
    const oldSession = editSession
    const editedSession = session

    // set order_number to the edited session
    editedSession.order_number = []
    editedSession.updated = true
    editedSession.available_on_day.forEach((day) => {
      const index = oldSession.available_on_day.findIndex((s) => s === day)
      let orderNumber = 1
      if (index > -1) {
        orderNumber = oldSession.order_number[index]
      } else {
        orderNumber = sessions.find((s) => s.step === day).sessions.length + 1
      }
      editedSession.order_number.push(orderNumber)
    })

    // replace the old session with the edited one in the state
    const newSessionsArray = [...sessions]
    oldSession.available_on_day.forEach((day) => {
      const step = newSessionsArray.find(({ step }) => step === day)
      step.sessions = step.sessions.filter(
        (s) =>
          s.title !== oldSession.title ||
          s.session_type_id !== oldSession.session_type_id
      )
    })
    editedSession.available_on_day.forEach((day, i) => {
      const step = newSessionsArray.find(({ step }) => step === day)
      step.sessions.push(editedSession)
      step.sessions.sort((a, b) => a.order_number[i] - b.order_number[i])
    })
    setSessions(newSessionsArray)
    setEditSession(null)
    setSessionCreatorVisible(false)
  }

  const onReorderSessions = (
    step: number,
    startIndex: number,
    endIndex: number
  ) => {
    const reorderedStep = sessions.find((s) => s.step === step)

    const result = Array.from(reorderedStep.sessions)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    for (let i = 0; i < result.length; i++) {
      let orderNumberIndex = 0
      if (treatmentType === 'scheduled') {
        orderNumberIndex = result[i].available_on_day.findIndex(
          (j) => j === step
        )
      }
      result[i].order_number[orderNumberIndex] = i + 1
      result[i].updated = true
    }

    setSessions(
      sessions.map((day) => {
        if (day.step === step) {
          return { step, sessions: result }
        }
        return day
      })
    )
  }

  const getSessionTitle = (session: Session): string => {
    const foundSession = allDoctorSessions.find(
      (s) => s.id === session.id && s.session_type === session.session_type_id
    )
    if (foundSession) {
      return foundSession.title
    }
    if (session.session_type_id === 3) {
      return surveys.find((s) => s.id === session.survey_id)?.name
    }
  }

  useEffect(() => {
    getSessionsRequest()
    fetchSurveys()
  }, [])

  useEffect(() => {
    if (allDoctorSessions) {
      loadTreatment()
    }
  }, [allDoctorSessions])

  if (
    loadingTreatment ||
    loadingSessions ||
    loadingSurveys ||
    savingNewTreatment ||
    updatingTreatment
  ) {
    return (
      <div className='loadingWrapper'>
        <ReactLoading type='bars' color='#ffffff' />
      </div>
    )
  }

  return (
    <div className='flex row treatmentContainer'>
      <TreatmentSidebar
        treatment={updatedTreatment}
        isNew={!id}
        selectedView={isCalendarView}
        surveys={surveys}
        changeView={setCalendarView}
        type={treatmentType}
        onChangeType={(type) => {
          if (type === 'normal' && sessions.length === 0) {
            setSessions([{ step: 1, sessions: [] }])
          }
          setTreatmentType(type)
        }}
        onTreatmentChange={setUpdatedTreatment}
      />
      <div className='flex column newTreatmentForm flexAuto dashboardOverflowContainer dashboardContent'>
        <Header
          title={`Treatment ${
            treatmentType === 'scheduled' ? 'Schedule' : 'Sessions'
          }`}
          description={` You are now editing a ${
            treatmentType === 'scheduled' ? 'scheduled' : 'not scheduled'
          } treatment`}
          action={
            <button
              className='btnGreen btn'
              onClick={id ? onUpdateTreatment : onSaveNewTreatment}
            >
              {id ? 'Update' : 'Save'}
            </button>
          }
        />
        <div className='flex column newTreatmentSchedule'>
          {treatmentType === 'scheduled' ? (
            <ScheduledTreatment
              view={isCalendarView}
              steps={sessions}
              addStep={addStep}
              onDeleteSession={onDeleteSession}
              onEditSession={onEditSession}
              importSession={importSession}
              createSession={createSession}
              onReorderSessions={onReorderSessions}
              id={id ? id : null}
            />
          ) : (
            <NonScheduledTreatment
              sessions={sessions[0]}
              onDeleteSession={onDeleteSession}
              onEditSession={onEditSession}
              importSession={importSession}
              createSession={createSession}
              onReorderSessions={onReorderSessions}
            />
          )}
        </div>
      </div>

      <SessionCreatorModal
        isVisible={isSessionCreatorVisible}
        surveys={surveys}
        onClose={() => {
          setEditSession(null)
          setSessionCreatorVisible(false)
        }}
        treatmentType={treatmentType}
        step={step}
        totalSteps={sessions.length}
        allDoctorSessions={allDoctorSessions}
        onSessionCreated={onSessionCreated}
        onSessionEdited={onSessionEdited}
        onAddExtraStep={() => addStep(sessions.length + 1, true)}
        editSession={editSession}
      />
      <SessionImporterModal
        isVisible={isSessionImporterVisible}
        onClose={() => setSessionImporterVisible(false)}
        treatmentType={treatmentType}
        sessions={allDoctorSessions}
        step={step}
        totalSteps={sessions.length}
        onAddExtraStep={() => addStep(sessions.length + 1, true)}
        onSessionImported={onSessionImported}
      />
    </div>
  )
}

export default TreatmentManager
