import React, { FC, useState } from 'react'
import Input from 'components/Input'
import {
  Question,
  Choice,
  MultipleChoice,
  Path,
  QuestionType,
} from 'models/Survey'
import ChoiceItem from './ChoiceItem'
import { useToast } from 'hooks/useToast'

type Props = {
  selectedQuestion: Question
  isEditing: boolean
  selectedPath: Path
  allPaths: Path[]
  onClose: () => void
  saveQuestion: (question: Question, path: Path) => void
  editQuestion: (question: Question, path: Path) => void
}

const QuestionModal: FC<Props> = ({
  selectedQuestion,
  isEditing,
  selectedPath,
  allPaths,
  onClose,
  saveQuestion,
  editQuestion,
}) => {
  const { showToast } = useToast()
  const [question, setQuestion] = useState<Question>(selectedQuestion)
  const [isConditional, setConditional] = useState<boolean>(
    !!(
      selectedQuestion.choices && selectedQuestion.choices[0]?.to_choice_path_id
    )
  )
  const [scaleMax, setScaleMax] = useState<number>(
    selectedQuestion.question_type_params?.to || null
  )
  const [choices, setChoices] = useState<Choice[]>(
    selectedQuestion.choices || [{ choice: '' }, { choice: '' }]
  )
  const [multipleChoices, setMultipleChoices] = useState<MultipleChoice[]>(
    selectedQuestion.choices || [{ choice: '' }, { choice: '' }]
  )

  const onFieldChange = (e) => {
    let { name, value } = e.target
    if (name === 'question_type') {
      value = Number(value)
    }
    if (name === 'is_required') {
      value = value === 'true'
    }
    setQuestion({ ...question, [name]: value })
  }

  const onChoiceChange = (choice: Choice, index: number) => {
    const updatedChoices = [...choices]
    updatedChoices[index] = choice
    setChoices(updatedChoices)
  }
  const onMultipleChoiceChange = (choice: Choice, index: number) => {
    const updatedChoices = [...multipleChoices]
    updatedChoices[index] = choice
    setMultipleChoices(updatedChoices)
  }

  const onDeleteChoice = (index: number) => {
    const updatedChoices = [...choices]
    updatedChoices.splice(index, 1)
    setChoices(updatedChoices)
  }
  const onDeleteMultipleChoice = (index: number) => {
    const updatedChoices = [...multipleChoices]
    updatedChoices.splice(index, 1)
    setMultipleChoices(updatedChoices)
  }

  const onConditionalChange = (e) => {
    const conditional = e.target.value === 'true'
    setConditional(conditional)
    if (conditional) {
      setQuestion({ ...question, is_required: true })
    } else {
      setChoices(choices.map((c) => ({ choice: c.choice })))
    }
  }

  const onScaleMaxChange = (e) => {
    setScaleMax(Number(e.target.value))
  }

  const isFormValid = (): boolean => {
    let valid = true
    if (!question.question_name || !question.question_type) {
      valid = false
    }
    if (question.question_type === QuestionType.Scale && !scaleMax) {
      valid = false
    }
    if (question.question_type === QuestionType.SingleChoice) {
      choices.forEach((c) => {
        if (!c.choice) {
          valid = false
        }
        if (isConditional && !c.to_choice_path_id) {
          valid = false
        }
      })
    }
    if (question.question_type === QuestionType.MultipleChoice) {
      multipleChoices.forEach((c) => {
        if (!c.choice) {
          valid = false
        }
      })
    }
    return valid
  }

  const onSaveQuestion = () => {
    if (!isFormValid()) {
      showToast({
        name: 'Error',
        value:
          'Please make sure you have filled in all the information required',
      })
      return
    }
    const savedQuestion = { ...question }
    delete savedQuestion.question_type_params
    delete savedQuestion.choices
    if (question.question_type === QuestionType.Scale) {
      savedQuestion.question_type_params = {
        from: 1,
        to: Number(scaleMax),
      }
    }
    if (question.question_type === QuestionType.SingleChoice) {
      savedQuestion.choices = choices
    } else if (question.question_type === QuestionType.MultipleChoice) {
      savedQuestion.choices = multipleChoices
    }
    if (isEditing) {
      editQuestion(savedQuestion, selectedPath)
    } else {
      saveQuestion(savedQuestion, selectedPath)
    }
    onClose()
  }

  return (
    <div className={'flex column modal active'}>
      <div onClick={onClose} className='flex column modalBackdrop'></div>
      <div className='flex column modalContent'>
        <div className='flex column modalContentContainer'>
          <div className='flex column modalHead'>
            <h2>{`${isEditing ? 'Edit' : 'Create'} a question`}</h2>
            <p>
              {`Make sure you provide all the information needed in order to ${
                isEditing ? 'edit' : 'create'
              }
            a question`}
            </p>
          </div>
          <Input
            type='text'
            name='question_name'
            value={question.question_name}
            onChange={onFieldChange}
            label='Question'
            placeholder='Your question'
          />
          <Input
            type='text'
            name='description'
            value={question.description}
            onChange={onFieldChange}
            label='Description'
            placeholder='Question description'
          />
          <div className='flex column inputWrapper'>
            <label>Required Question</label>
            <select
              name='is_required'
              value={question.is_required.toString()}
              disabled={question.question_type === 4 && isConditional}
              onChange={onFieldChange}
            >
              <option value='true'>Yes</option>
              <option value='false'>No</option>
            </select>
          </div>
          <div className='flex column inputWrapper'>
            <label>Answer Type</label>
            <select
              name='question_type'
              value={question.question_type}
              onChange={onFieldChange}
            >
              <option value={null}>Select a question type</option>
              <option value='1'>Scale</option>
              <option value='2'>Any Number</option>
              <option value='3'>Yes/No</option>
              <option value='4'>Single Choice</option>
              <option value='5'>Multiple Choice</option>
              <option value='6'>Free Text</option>
            </select>
          </div>

          {question.question_type === 5 && (
            <div className='flex column'>
              {multipleChoices.map((choice, i) => (
                <ChoiceItem
                  key={`choice-${i}`}
                  choice={choice}
                  index={i}
                  currentPathId={selectedPath.id}
                  canDelete={choices.length > 2}
                  onChoiceChange={onMultipleChoiceChange}
                  onDeleteChoice={onDeleteMultipleChoice}
                />
              ))}
              <button
                className='btn btnBlue'
                onClick={() =>
                  setMultipleChoices([...multipleChoices, { choice: '' }])
                }
              >
                Add A Choice
              </button>
            </div>
          )}
          {question.question_type === 4 && (
            <div className='flex column inputWrapper'>
              <label>Multiple Choice Type</label>
              <select
                name='conditionalMulti'
                value={isConditional.toString()}
                onChange={onConditionalChange}
              >
                <option value='true'>Conditional</option>
                <option value='false'>Normal</option>
              </select>
            </div>
          )}
          {question.question_type === 1 && (
            <Input
              name='scaleMax'
              value={scaleMax}
              onChange={onScaleMaxChange}
              label='Scale maximum'
              type='number'
              placeholder='From 1 to '
            />
          )}
          {question.question_type === 4 && (
            <div className='flex column'>
              {choices.map((choice, i) => (
                <ChoiceItem
                  key={`choice-${i}`}
                  choice={choice}
                  index={i}
                  currentPathId={selectedPath.id}
                  canDelete={choices.length > 2}
                  isConditional={isConditional}
                  possiblePaths={allPaths.filter((p) => p.id > selectedPath.id)}
                  onChoiceChange={onChoiceChange}
                  onDeleteChoice={onDeleteChoice}
                />
              ))}
              <button
                className='btn btnBlue'
                onClick={() => setChoices([...choices, { choice: '' }])}
              >
                Add A Choice
              </button>
            </div>
          )}
        </div>
        <div className='flex row sessionActionRow'>
          <div className='flex column flexAuto'>
            <button className='btn btnGrey' onClick={onClose}>
              Cancel
            </button>
          </div>
          <div className='flex column'>
            <button className='btn btnGreen' onClick={onSaveQuestion}>
              {isEditing ? 'Update' : 'Add'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionModal
