import React, { FC } from 'react'
import Session from 'models/Session'
import ScheduledDay from './ScheduledDay'
import { TreatmentStep } from '../TreatmentManager'

type Props = {
  steps: TreatmentStep[]
  view: boolean
  id?: number
  addStep: (step: number, addStep: boolean) => void
  onEditSession: (session: Session) => void
  onDeleteSession: (session: Session, step: number) => void
  importSession: (step: number) => void
  createSession: (step: number) => void
  onReorderSessions: (
    step: number,
    startIndex: number,
    endIndex: number
  ) => void
}

const ScheduledTreatment: FC<Props> = ({
  steps,
  addStep,
  onDeleteSession,
  onEditSession,
  importSession,
  createSession,
  onReorderSessions,
  id,
  view,
}) => {
  return (
    <div
      className={`flex column flex-grid-12 last-flex ${
        view ? 'calendarView' : ''
      }`}
    >
      {steps.map((step) => (
        <ScheduledDay
          step={step}
          importSession={importSession}
          createSession={createSession}
          onDeleteSession={onDeleteSession}
          onEditSession={onEditSession}
          onReorderSessions={onReorderSessions}
          addStep={addStep}
          id={id ? id : null}
        />
      ))}
      {steps.length === 0 && (
        <div className='flex column flexAuto align-center listItemWrapper'>
          <h3>No Steps in Schedule</h3>
          <p>There are no steps in this Treatment Schedule yet</p>
        </div>
      )}
    </div>
  )
}

export default ScheduledTreatment
