import React, { FC } from 'react'
import 'assets/styles/header.scss'
import { useUser } from 'hooks/useUser'
import { useDispatch } from 'react-redux'
import { logout } from 'ducks/user'

type Props = {
  title: string
  description: string
  action?: React.ReactNode
}
const Header: FC<Props> = ({ title, description, action }) => {
  const user = useUser()
  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(logout())
  }

  return (
    <div className='flex row headerBar align-center'>
      {action ? <div className='flex column headActions'>{action}</div> : null}
      <div className='flex column flexAuto justify-center'>
        <h5>{title}</h5>
        <p>
          <small>{description}</small>
        </p>
      </div>
      <div className='flex row align-center'>
        <div className='flex column align-end'>
          <h5>{`${user.first_name} ${user.last_name}`}</h5>
          <p onClick={onLogout}>
            <small className={'pointer'}>Logout</small>
          </p>
        </div>
        <div className='flex column avatar'>
          <h5>{`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}</h5>
        </div>
      </div>
    </div>
  )
}
export default Header
