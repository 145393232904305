import { useState, useRef, useCallback } from 'react'
import axios, { Method, AxiosResponse } from 'axios'

import { useToast } from './useToast'
import config from 'api/config'

type SendRequestParams = {
  url?: string
  body?: any
  params?: any
  onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
}

type UseApi<T> = [
  (requestParams?: SendRequestParams) => Promise<AxiosResponse<any>>,
  { loading: boolean; data: T; statusCode: number },
  (message?: string) => void
]

axios.defaults.baseURL = config.api_host
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['Pragma'] = 'no-cache'

export const useApi = <T>(method: Method, baseUrl?: string): UseApi<T> => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(false)
  const [statusCode, setStatusCode] = useState<number | undefined>()
  const { showToast } = useToast()

  const CancelToken = axios.CancelToken
  const source = useRef(CancelToken.source())

  const cancelRequest = (message?: string) => {
    source.current.cancel(message)
  }

  const sendRequest = useCallback(
    async ({ body, url, params, onUploadProgress }: SendRequestParams = {}) => {
      try {
        setLoading(true)

        const response = await axios.request({
          url: url || baseUrl,
          method,
          data: body,
          onUploadProgress,
          cancelToken: source.current.token,
          params,
        })

        setStatusCode(response.status)
        setData(response.data)
        return response
      } catch (err) {
        showToast({
          value: err.response?.data?.error || err.message,
          name: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [method, baseUrl]
  )

  return [sendRequest, { loading, data, statusCode }, cancelRequest]
}
