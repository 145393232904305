import React, { FC, useEffect, useState, useCallback } from 'react'
import { debounce } from 'lodash'
import ReactLoading from 'react-loading'
import { useAllMedia } from 'hooks/useAllMedia'
import Header from 'components/Header'
import ListUtilsBar from 'components/ListUtilsBar'
import MediaItem from './components/MediaItem'
import './mediaCenter.scss'
import FileUploadModal from './components/FileUploadModal'
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'
import { ReactComponent as Empty } from 'assets/img/humanitas/empty.svg'

const orderOptions = [
  { value: 'name,asc', name: 'Name Ascending' },
  { value: 'name,desc', name: 'Name Descending' },
  { value: 'created_at,asc', name: 'Last Uploaded' },
  { value: 'created_at,desc', name: 'First Uploaded' },
]
const filterOptions = [
  { value: '', name: 'All' },
  { value: 'type,image', name: 'Images' },
  { value: 'type,video', name: 'Videos' },
]
const MediaCenter: FC = () => {
  const { allMedia, fetchAllMedia, loading } = useAllMedia()
  const [isUploadModalVisible, setUploadModalVisible] = useState(false)
  const [order, setOrder] = useState('name,asc')
  const [filter, setFilter] = useState('')
  const [search, setSearch] = useState('')
  const allParams = {
    order_by: order,
    filter_by: filter,
    search_by: search.length > 2 ? search : '',
  }

  const debauncedFetch = useCallback(
    debounce((params) => {
      fetchAllMedia(params)
    }, 600),
    [fetchAllMedia]
  )
  useDidUpdateEffect(() => {
    debauncedFetch(allParams)
  }, [search])

  useEffect(() => {
    fetchAllMedia(allParams)
  }, [order, filter])

  return (
    <div className='flex column dashboardContent'>
      <Header
        title='Media Center'
        description={`${allMedia.length} Files Available`}
      />
      <ListUtilsBar
        pageType='File'
        possibleOrders={orderOptions}
        order={order}
        setOrder={setOrder}
        filter={filter}
        setFilter={setFilter}
        filterOptions={filterOptions}
        search={search}
        searchFor={setSearch}
        action={() => setUploadModalVisible(true)}
      />
      <div className='flex row flexWrap'>
        {allMedia.length > 0 ? (
          allMedia.map((item, i) => (
            <MediaItem key={i.toString()} item={item} />
          ))
        ) : (
          <div className='flex column flex-grid-12 last-flex'>
            <div className='flex column whitebox flexAuto align-center emptyBox'>
              <Empty />
              <h5>
                No Media {search.length > 3 ? ` containing "${search}"` : null}
              </h5>
              <p>
                <small>
                  {`There are no media files ${
                    search.length < 3
                      ? 'yet. Feel free to create one in order to get started.'
                      : 'for this search.'
                  }`}
                </small>
              </p>
            </div>
          </div>
        )}
      </div>
      {isUploadModalVisible && (
        <FileUploadModal
          onUploadSuccess={fetchAllMedia}
          onClose={() => {
            setUploadModalVisible(false)
          }}
        />
      )}
      {loading && (
        <div className='loadingWrapper'>
          <ReactLoading type='bars' color='#ffffff' />
        </div>
      )}
    </div>
  )
}

export default MediaCenter
