import React, { FC, useState, useEffect } from 'react'
import Switch from 'react-switch'
import * as Showdown from 'showdown'
import 'react-mde/lib/styles/css/react-mde-all.css'
import ReactMde from 'react-mde'
import Input from 'components/Input'
import DayBubble from 'components/DayBubble'
import Session from 'models/Session'
import Survey from 'models/Survey'
import { TreatmentType } from '../TreatmentManager'
import FileImportModal from 'components/FileImportModal'
import { useToast } from 'hooks/useToast'

type Props = {
  isVisible: boolean
  onClose: () => void
  surveys: Survey[]
  treatmentType: TreatmentType
  step: number
  totalSteps: number
  onAddExtraStep: () => void
  allDoctorSessions: Session[]
  onSessionCreated: (session: Session) => void
  onSessionEdited: (session: Session) => void
  editSession?: Session
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

const SessionCreatorModal: FC<Props> = ({
  isVisible,
  surveys,
  treatmentType,
  step,
  totalSteps,
  allDoctorSessions,
  editSession,
  onClose,
  onAddExtraStep,
  onSessionCreated,
  onSessionEdited,
}) => {
  const [session, setSession] = useState<Session>({
    session_type_id: 1,
    available_on_day: [],
  })
  const [mediaImporterVisible, setMediaImporterVisible] = useState(false)
  const [isEveryDay, setEveryDay] = useState(false)
  const [editorTab, setEditorTab] = useState<'write' | 'preview'>('write')
  const { showToast } = useToast()

  const onSavePress = () => {
    let valid = false
    if (session.session_type_id === 3 && session.survey_id) {
      valid = true
    } else if (
      session.session_type_id === 1 &&
      session.url &&
      session.title &&
      session.description
    ) {
      valid = true
    } else if (
      session.session_type_id === 2 &&
      session.image &&
      session.title &&
      session.content
    ) {
      valid = true
    } else if (session.isExisting) {
      valid = true
    } else {
      showToast({
        name: 'Error',
        value:
          'Please make sure you have filled in all the information required',
      })
    }

    if (
      session.session_type_id !== 3 &&
      allDoctorSessions.some((s) => s.title === session.title) &&
      !editSession
    ) {
      valid = false
      showToast({
        name: 'Error',
        value: `Session ${session.title} has been already used. Please import it`,
      })
    }

    if (valid) {
      if (editSession) {
        onSessionEdited(session)
      } else {
        onSessionCreated(session)
      }
    }
  }

  useEffect(() => {
    if (editSession) {
      setSession(editSession)
    } else {
      setSession({
        session_type_id: 1,
        available_on_day: [step],
        title: null,
      })
    }
  }, [isVisible])

  const onFieldChange = (e) => {
    let { name, value } = e.target
    if (name === 'session_type_id' || name === 'survey_id') {
      value = Number(value)
    }
    setSession({ ...session, [name]: value })
  }

  const onMarkdownChange = (content: string) => {
    setSession({ ...session, content })
  }

  const onAvailableEveryDayChange = (checked: boolean) => {
    setEveryDay(checked)
    if (checked) {
      const available_on_day = Array.from(
        { length: totalSteps },
        (value, key) => key + 1
      )
      setSession({ ...session, available_on_day })
    } else {
      setSession({ ...session, available_on_day: [step] })
    }
  }

  const onImportVideoToSession = (url: string, title: string) => {
    setSession({ ...session, url, title })
    setMediaImporterVisible(false)
  }

  const removeSessionFromDay = (day: number) => {
    if (session.available_on_day.length === 1) {
      return
    }
    setSession({
      ...session,
      available_on_day: session.available_on_day.filter((i) => i !== day),
    })
  }

  const addSessionToDay = (day: number) => {
    setSession({
      ...session,
      available_on_day: [...session.available_on_day, day],
    })
  }

  let daysSelector = []
  for (var i = 1; i <= totalSteps; i++) {
    if (session.available_on_day?.includes(i)) {
      daysSelector.push(
        <DayBubble step={i} onClick={removeSessionFromDay} active={true} />
      )
    } else {
      daysSelector.push(
        <DayBubble step={i} onClick={addSessionToDay} active={false} />
      )
    }
  }

  return (
    <div>
      <div className={`flex column modal ${isVisible && 'active'}`}>
        <div onClick={onClose} className='flex column modalBackdrop'></div>
        <div className='flex column modalContent'>
          <div className='flex column modalContentContainer'>
            <div className='flex column modalHead'>
              <h2>{editSession ? 'Edit a Session' : 'Create a Session'}</h2>
              <p>
                Make sure you provide all the information needed in order to
                create a sessions
              </p>
            </div>
            {!editSession && (
              <div className='flex column inputWrapper'>
                <label>Session Type</label>
                <select
                  name='session_type_id'
                  value={session.session_type_id}
                  onChange={onFieldChange}
                >
                  <option value={1}>Video</option>
                  <option value={2}>Article</option>
                  <option value={3}>Survey</option>
                </select>
              </div>
            )}
            {session.session_type_id === 3 && !editSession && (
              <div className='flex column inputWrapper'>
                <label>Session Survey</label>
                <select
                  name='survey_id'
                  value={session.survey_id}
                  onChange={onFieldChange}
                >
                  <option value={null}>Select a survey</option>
                  {surveys.map((item) => {
                    return <option value={item.id}>{item.name}</option>
                  })}
                </select>
              </div>
            )}
            {session.session_type_id !== 3 && !session.isExisting && (
              <Input
                type='text'
                name='title'
                value={session.title}
                onChange={onFieldChange}
                label='Title'
                placeholder='Session Title'
              />
            )}
            {session.session_type_id === 1 && !session.isExisting && (
              <div className='flex column'>
                <Input
                  type='text'
                  name='description'
                  value={session.description}
                  onChange={onFieldChange}
                  label='Description'
                  placeholder='Session Description'
                />
                <div className='flex row importField'>
                  <Input
                    type='text'
                    name='url'
                    value={session.url}
                    onChange={onFieldChange}
                    label='Video Url'
                    placeholder='Video file or Youtube embeded URL'
                  />
                  <button
                    className='btn btnGreen videoImportBtn'
                    onClick={() => setMediaImporterVisible(true)}
                  >
                    Import
                  </button>
                </div>
              </div>
            )}
            {session.session_type_id === 2 && !session.isExisting && (
              <div className='flex column'>
                <Input
                  type='text'
                  value={session.image}
                  onChange={onFieldChange}
                  name='image'
                  label='Image Url'
                  placeholder='Session Image url'
                />
                <div className='flex column inputWrapper'>
                  <label>Article Content</label>
                  <ReactMde
                    onChange={onMarkdownChange}
                    selectedTab={editorTab}
                    onTabChange={setEditorTab}
                    value={session.content}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(converter.makeHtml(markdown))
                    }
                  />
                </div>
              </div>
            )}
            {treatmentType === 'scheduled' &&
              Array.isArray(session.available_on_day) && (
                <div className='flex row align-center availabilityWrapper'>
                  <h5>Available every day</h5>
                  <div className='flex column flexAuto align-end'>
                    <Switch
                      onChange={onAvailableEveryDayChange}
                      checked={isEveryDay}
                    />
                  </div>
                </div>
              )}
            {treatmentType === 'scheduled' && !isEveryDay && (
              <div className='flex column availabilityWrapper'>
                <h5>Available on</h5>
                <div className='flex row bubblesWrapper'>
                  {daysSelector}
                  <div className='dayBubble' onClick={onAddExtraStep}>
                    Add Day
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='flex row sessionActionRow'>
            <div className='flex column flexAuto'>
              <button className='btn btnGrey' onClick={onClose}>
                Cancel
              </button>
            </div>
            <div className='flex column'>
              <button className='btn btnGreen' onClick={onSavePress}>
                {editSession ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <FileImportModal
        active={mediaImporterVisible}
        onClose={() => setMediaImporterVisible(false)}
        onImport={onImportVideoToSession}
      />
    </div>
  )
}

export default SessionCreatorModal
