import { useSelector, useDispatch } from 'react-redux'
import { getAllMedia, setMedia } from 'ducks/media'
import { useApi } from './useApi'

export const useAllMedia = () => {
  const dispatch = useDispatch()
  const [getAllMediaRequest, { loading }] = useApi('GET', '/asset')

  const fetchAllMedia = async (params?) => {
    const res = await getAllMediaRequest({params})
    if (res?.data) {
      dispatch(setMedia(res.data))
    }
  }

  return {
    allMedia: useSelector(getAllMedia),
    fetchAllMedia,
    loading,
  }
}
