import React, { FC } from 'react'

type Props = {
  active: boolean
  toggle: () => void
  yesAction: () => void
}

const SureModal: FC<Props> = ({ active, toggle, yesAction }) => {
  return (
    <div
      className={`flex column modal justify-center align-center ${
        active && 'active'
      }`}
    >
      <div onClick={toggle} className='flex column modalBackdrop'></div>
      <div className='flex row modalContent flex-grid-6 last-flex'>
        <div className='flex column modalContentContainer'>
          <h2>Are you sure?</h2>
          <p>
            You most probably cannot revert the outcome of this action. Are you
            sure you want to continue?
          </p>
        </div>
        <div className='flex row align-center justify-end flexAuto'>
          <div className='flex column firstBtn'>
            <button className='btn btnGrey' onClick={toggle}>
              Cancel
            </button>
          </div>
          <div className='flex column'>
            <button
              className='btn btnGreen'
              onClick={() => {
                yesAction()
                toggle()
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SureModal
