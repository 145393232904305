type Survey = {
  id?: number
  survey_id?: number
  name: string
  surveyInfo: SurveyInfo
  created_at: string
}

type SurveyInfo = {
  paths: Path[]
  questions: Question[]
}

export type Path = {
  id: number
  questions: number[]
  questionObjects?: Question[]
}

export type Question = {
  id: number
  question_name?: string
  question_type?: number
  description?: string
  is_required?: boolean
  question_id?: number
  question_type_params?: {
    from: number
    to: number
  }
  choices?: Choice[]
}

export type Choice = {
  id?: number
  choice: string
  from_choice_path_id?: number
  to_choice_path_id?: number
}
export type MultipleChoice = {
  id?: number
  choice: string
}

export const QuestionTypeNames = {
  1: 'Scale 1 to ',
  2: 'Any Number',
  3: 'Yes/No',
  4: 'Single Choice',
  5: 'Multiple Choice',
  6: 'Free Text',
}

export enum QuestionType {
  Scale = 1,
  AnyNumber = 2,
  YesNo = 3,
  SingleChoice = 4,
  MultipleChoice = 5,
  FreeText = 6,
}

export default Survey
