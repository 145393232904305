import React, { FC, useState } from 'react'
import Session from 'models/Session'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import SessionTile from './SessionTile'
import { TreatmentStep, TreatmentType } from '../TreatmentManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

type Props = {
  step: TreatmentStep
  id?: number
  treatmentType?: TreatmentType
  importSession: (step: number) => void
  createSession: (step: number) => void
  addStep?: (step: number, addStep: boolean) => void
  onEditSession: (session: Session) => void
  onDeleteSession: (session: Session, step: number) => void
  onReorderSessions: (
    step: number,
    startIndex: number,
    endIndex: number
  ) => void
}

const ScheduledDay: FC<Props> = ({
  step,
  treatmentType = 'scheduled',
  id,
  importSession,
  createSession,
  onDeleteSession,
  onEditSession,
  onReorderSessions,
  addStep,
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    onReorderSessions(step.step, result.source.index, result.destination.index)
  }
  let [openAddStep, setOpenAddStep] = useState(false)
  return (
    <div className='flex column newTreatmentScheduleDay'>
      <div className='flex row dayHead align-center'>
        <div className='flex column'>
          {treatmentType === 'scheduled' && <h5>Step</h5>}
          {treatmentType === 'scheduled' && <h3>{step.step}</h3>}
          {treatmentType !== 'scheduled' && <h3>Treatment Sessions</h3>}
        </div>
        <div className='flex row flexAuto justify-end align-center'>
          {treatmentType === 'scheduled' ? (
            <div className='flex column addStepWrapper'>
              <button
                className={`btn btnLined ${openAddStep ? 'active' : ''}`}
                onClick={() => setOpenAddStep(!openAddStep)}
              >
                <FontAwesomeIcon
                  size='sm'
                  color='#00764e'
                  icon={faPlusCircle}
                />
                Add a Step
              </button>
              <div className={`addStepList ${openAddStep ? 'active' : ''}`}>
                <ul>
                  <li
                    onClick={() => {
                      addStep(step.step - 1, true)
                      setOpenAddStep(!openAddStep)
                    }}
                  >
                    <p>Before this step</p>
                  </li>
                  <li
                    onClick={() => {
                      addStep(step.step, true)
                      setOpenAddStep(!openAddStep)
                    }}
                  >
                    <p>After this step</p>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
          {id || treatmentType !== 'scheduled' ? null : (
            <div
              className='flex column deleteAction'
              onClick={() => addStep(step.step - 1, false)}
            >
              <FontAwesomeIcon size='lg' color='#f25352' icon={faTrashAlt} />
            </div>
          )}
        </div>
      </div>
      <div className='flex column newTreatmentDailyList'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'droppable'} direction='vertical'>
            {(provided) => (
              <ul
                className='flex column'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {step.sessions.length > 0 ? (
                  step.sessions.map((session, i) => (
                    <li>
                      <SessionTile
                        item={session}
                        position={i}
                        treatmentType={treatmentType}
                        onDeleteSession={(session) =>
                          onDeleteSession(session, step.step)
                        }
                        onEditSession={onEditSession}
                      />
                    </li>
                  ))
                ) : (
                  <li>
                    <div className='flex column listItemWrapper emptyItem'>
                      <h4>No Scheduled Sessions</h4>
                      <p>Please create your first session</p>
                    </div>
                  </li>
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className='flex row dayActionsRow justify-end'>
          <button
            className='btn btnGreen'
            onClick={() => {
              importSession(step.step)
            }}
          >
            Import a session
          </button>
          <button
            className='btn btnGreen'
            onClick={() => {
              createSession(step.step)
            }}
          >
            Create a session
          </button>
        </div>
      </div>
    </div>
  )
}

export default ScheduledDay
