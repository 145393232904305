import {
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit'
import logger from 'redux-logger'

import notifications from './notifications'
import treatments from './treatments'
import patients from './patients'
import surveys from './surveys'
import media from './media'
import user from './user'

const middleware = getDefaultMiddleware()
middleware.push(logger)

const store = configureStore({
  reducer: {
    user,
    notifications,
    treatments,
    patients,
    surveys,
    media,
  },
  middleware,
})

export type RootState = ReturnType<typeof store.getState>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default store
