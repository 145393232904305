import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import SureModal from 'components/SureModal'
import Treatment from 'models/Treatment'

import { ReactComponent as Calendar } from 'assets/img/calendar.svg'
import { ReactComponent as Session } from 'assets/img/session.svg'
import './treatmentBox.scss'

type Props = {
  treatment: Treatment
  editTreatment: (treatment: Treatment) => void
  deleteTreatment: (treatment: Treatment) => void
}

const TreatmentBox: FC<Props> = ({
  treatment,
  editTreatment,
  deleteTreatment,
}) => {
  const [optionsVisible, setOptionsVisible] = useState(false)
  const [deleteConfirmActive, setDeleteConfirmActive] = useState(false)

  return (
    <div className='flex column flex-grid-3 listCardBox treatmentBox'>
      <div
        className={`dropdownBackdrop ${optionsVisible && 'active'}`}
        onClick={() => setOptionsVisible(!optionsVisible)}
      ></div>
      <div className='flex column whitebox'>
        <div className='flex column cardContent'>
          <div className='flex row'>
            <div className='flex column flexAuto align-start'>
              {treatment.days ? (
                <div className='cardPill scheduledPill'>Scheduled</div>
              ) : (
                <div className='cardPill normalPill'>Non Scheduled</div>
              )}
            </div>
            <div className='flex column justify-center whiteBoxEditWrapper'>
              <div>
                <FontAwesomeIcon
                  size='lg'
                  color='rgba(0,0,0,.3)'
                  icon={faEllipsisH}
                  onClick={() => {
                    setOptionsVisible(!optionsVisible)
                  }}
                />
                <div
                  className={`dropdownWrapper ${optionsVisible && 'active'}`}
                >
                  <ul>
                    <li
                      onClick={() => {
                        setOptionsVisible(false)
                        editTreatment(treatment)
                      }}
                    >
                      Edit
                    </li>
                    <li
                      onClick={() => {
                        setOptionsVisible(false)
                        setDeleteConfirmActive(true)
                      }}
                    >
                      Delete
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='flex row'>
            <div className='flex column flexAuto'>
              <h5>{treatment.name}</h5>
              <p>{treatment.description}</p>
            </div>
          </div>
        </div>
        <div className='flex row cardMeta'>
          {treatment.days ? (
            <div className='flex row align-center'>
              <Calendar />
              <p>
                <small>{`${treatment.days} Steps`}</small>
              </p>
            </div>
          ) : (
            <div className='flex row align-center'>
              <Session />
              <p>
                <small>{`${treatment.sessions} Sessions`}</small>
              </p>
            </div>
          )}
        </div>
      </div>
      <SureModal
        active={deleteConfirmActive}
        yesAction={() => deleteTreatment(treatment)}
        toggle={() => setDeleteConfirmActive(!deleteConfirmActive)}
      />
    </div>
  )
}

export default TreatmentBox
