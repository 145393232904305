import Survey from './Survey'

type Session = {
  id?: number
  available_on_day?: number[]
  content?: string
  url?: string
  image?: string
  order_number?: number[]
  session_type_id?: number
  session_type?: number
  survey_id?: number
  title?: string
  description?: string
  survey?: Survey
  isExisting?: boolean
  updated?: boolean
}

export const SESSION_TYPE_NAMES = {
  1: 'Video',
  2: 'Article',
  3: 'Survey',
}

export default Session
