import React, { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'

import './sidebar.scss'
import { ReactComponent as LogoSmall } from 'assets/img/humanitas/logoSmall.svg'
import { ReactComponent as Patients } from 'assets/img/patients.svg'
import { ReactComponent as Treatments } from 'assets/img/treatments.svg'
import { ReactComponent as Surveys } from 'assets/img/surveys.svg'
import { ReactComponent as MediaCenter } from 'assets/img/mediaCenter.svg'
import { ReactComponent as ExpandMenu } from 'assets/img/expandMenu.svg'
import { ReactComponent as ShrinkMenu } from 'assets/img/shirnkMenu.svg'

const SideBar: FC = () => {
  let [expanded, setExpanded] = useState(false)
  return (
    <div
      className={`flex column whitebox sidebarWrapper${
        expanded ? ' expanded' : ''
      }`}
    >
      <div className='flex column flexAuto sidebarContent'>
        <div className='flex row justify-center logoWrapper'>
          <LogoSmall />
        </div>
        <ul>
          <li>
            <NavLink activeClassName='active' to='/dashboard/home'>
              <Patients />
              <span>Patients</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to='/dashboard/treatments'>
              <Treatments />
              <span>Treatments</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to='/dashboard/surveys'>
              <Surveys />
              <span>Surveys</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to='/dashboard/media'>
              <MediaCenter />
              <span>Media Center</span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className='flex column align-center'>
        <ul>
          <li onClick={() => setExpanded(!expanded)}>
            {expanded ? <ShrinkMenu /> : <ExpandMenu />}
            <span>Shrink Menu</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideBar
