import React, { FC, useEffect, useState } from 'react'
import NotificationToast from 'components/NotificationToast'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import Login from 'containers/Login/Login'
import Dashboard from 'containers/Dashboard/Dashboard'
import Authenticate from 'containers/Authenticate/Authenticate'
import { useToast } from 'hooks/useToast'
import { useDispatch } from 'react-redux'
import { setUser, logout } from 'ducks/user'
import PrivateRoute from 'components/PrivateRoute'
import { useUser } from 'hooks/useUser'

const App: FC = () => {
  const { notification, hideToast, showToast } = useToast()
  const [loadedUser, setLoadedUser] = useState(false)
  const user = useUser()
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'))
      if (storedUser) {
        dispatch(setUser(storedUser))
      }
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(logout())
            if (error.response?.data?.error === 'jwt expired') {
              showToast({
                value: 'Your session has expired. Please login again.',
                name: 'error',
              })
              return
            }
          }
          return Promise.reject(error)
        }
      )
    } catch (error) {
      showToast({ name: 'Error', value: error.message })
    } finally {
      setLoadedUser(true)
    }
  }, [])

  return (
    <div className='flex containerFluid column'>
      {notification?.name && (
        <NotificationToast notification={notification} onClick={hideToast} />
      )}
      {loadedUser && (
        <Router>
          <Switch>
            <Route exact path='/'>
              {user && user.jwt ? <Redirect to='/dashboard/home' /> : <Login />}
            </Route>
            <PrivateRoute path='/dashboard'>
              <Dashboard />
            </PrivateRoute>
            <Route path='/authenticate/:key/:email' component={Authenticate} />
          </Switch>
        </Router>
      )}
    </div>
  )
}

export default App
