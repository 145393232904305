import React, { FC } from 'react'
import { Path, Question, QuestionType } from 'models/Survey'
import QuestionBox from './QuestionBox'

type Props = {
  path: Path
  onDeleteQuestion: (question: Question, path: Path) => void
  onEditQuestion: (question: Question, path: Path) => void
  onAddQuestion: (path: Path) => void
}

const SurveyPath: FC<Props> = ({
  path,
  onDeleteQuestion,
  onEditQuestion,
  onAddQuestion,
}) => {
  const questions = path.questionObjects
  const lastQuestion = questions[questions.length - 1]

  const onDelete = (question: Question) => {
    onDeleteQuestion(question, path)
  }

  const onEdit = (question: Question) => {
    onEditQuestion(question, path)
  }

  return (
    <li className='flex column'>
      <div className='flex row align-center dayHead'>
        <div className='flex column'>
          <h5>Path</h5>
          <h3>{`${path.id + 1}`}</h3>
        </div>
        <div className='flex flexAuto column align-end'>
          <button className='btn btnGreen' onClick={() => onAddQuestion(path)}>
            Add A Question
          </button>
        </div>
      </div>
      {questions.length > 0 ? (
        <div className='flex column'>
          <ul>
            {questions.map((question) => (
              <QuestionBox
                key={`question-${question.id}`}
                question={question}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ))}
          </ul>
        </div>
      ) : (
        <div className='flex column whitebox flexAuto listItemWrapper emptyItem'>
          <h4>No Questions</h4>
          <p>There are no questions in this path yet</p>
          <div className='flex column'></div>
        </div>
      )}
    </li>
  )
}

export default SurveyPath
