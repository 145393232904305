import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import User from 'models/User'
import { RootState, AppThunk } from './store'

export type UserState = {
  current: User
}

const initialState: UserState = {
  current: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<User>) => {
      state.current = action.payload
    },
    logout: (state: UserState) => (state = initialState),
  },
})

// Selectors:
export const getUser = (state: RootState): User => state.user.current

// Exports
const { actions, reducer } = userSlice
export default reducer

// Thunks
export const setUser = (user: User): AppThunk => (dispatch) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${user.jwt}`
  dispatch(actions.setUser(user))
}

export const logout = (): AppThunk => (dispatch) => {
  axios.defaults.headers.common['Authorization'] = undefined
  localStorage.removeItem('user')
  dispatch(actions.logout())
}
