import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import Patients from 'containers/Patients/Patients'
import Treatments from 'containers/Treatments/Treatments'
import Surveys from 'containers/Surveys/Surveys'
import TreatmentManager from 'containers/TreatmentManager/TreatmentManager'
import NewSurvey from 'containers/NewSurvey/NewSurvey'
import MediaCenter from 'containers/MediaCenter/MediaCenter'
import SideBar from './components/SideBar'
import './dashboard.scss'

const Dashboard: FC = () => {
  return (
    <div className='flex row dashboardWrapper'>
      <SideBar />
      <div className='flex column flexAuto dashboardOverflowContainer'>
        <Switch>
          <Route exact path='/dashboard/home' component={Patients} />
          <Route exact path='/dashboard/treatments' component={Treatments} />
          <Route exact path='/dashboard/surveys' component={Surveys} />
          <Route exact path='/dashboard/media' component={MediaCenter} />
          <Route
            exact
            path='/dashboard/treatments/newTreatment'
            component={TreatmentManager}
          />
          <Route
            exact
            path='/dashboard/treatments/editTreatment/:id'
            component={TreatmentManager}
          />
          <Route
            exact
            path='/dashboard/surveys/newSurvey'
            component={NewSurvey}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Dashboard
