import React, { FC, useState, useRef } from 'react'
import Input from 'components/Input'
import Header from 'components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ReactLoading from 'react-loading'
import { Path, Question } from 'models/Survey'
import './newSurvey.scss'
import SurveyPath from './components/SurveyPath'
import QuestionModal from './components/QuestionModal'
import SurveySidebar from './components/SurveySidebar'
import { useApi } from 'hooks/useApi'
import { useToast } from 'hooks/useToast'
import { useHistory } from 'react-router-dom'

const NewSurvey: FC = () => {
  const { showToast } = useToast()
  const history = useHistory()
  const [name, setName] = useState('')
  const [calendarView, setCalendarView] = useState(false)
  const [paths, setPaths] = useState<Path[]>([
    {
      id: 0,
      questions: [],
      questionObjects: [],
    },
  ])
  const [selectedPath, setSelectedPath] = useState<Path>()
  const [selectedQuestion, setSelectedQuestion] = useState<Question>()
  const [isEditing, setEditing] = useState(false)
  const allQuestions = useRef<Question[]>([])
  const [saveSurveyRequest, { loading }] = useApi('POST', '/doctor/survey')

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const saveSurvey = async () => {
    if (!name || paths.length === 0 || allQuestions.current.length === 0) {
      showToast({
        name: 'Error',
        value:
          'Please make sure you have filled in all the information required',
      })
      return
    }
    const res = await saveSurveyRequest({
      body: {
        name: name,
        paths: paths.map((p) => ({ id: p.id, questions: p.questions })),
        questions: allQuestions.current,
      },
    })
    if (res?.data?.success) {
      showToast({
        name: 'Success',
        value: 'Survey was successfully created',
      })
      history.push('/dashboard/surveys')
    }
  }

  const saveQuestion = (question: Question, path: Path) => {
    const updatedPaths = [...paths]
    const foundPathIndex = updatedPaths.findIndex(({ id }) => id === path.id)
    updatedPaths[foundPathIndex] = {
      ...path,
      questionObjects: [...path.questionObjects, question],
      questions: [...path.questions, question.id],
    }
    setPaths(updatedPaths)
    allQuestions.current.push(question)
    showToast({
      name: 'Success',
      value: 'Question was successfully added',
    })
  }

  const editQuestion = (question: Question, path: Path) => {
    const updatedPaths = [...paths]
    const foundPathIndex = updatedPaths.findIndex(({ id }) => id === path.id)
    updatedPaths[foundPathIndex] = {
      ...path,
      questionObjects: path.questionObjects.map((q) => {
        if (q.id === question.id) {
          return question
        }
        return q
      }),
    }
    setPaths(updatedPaths)
    const editedIndex = allQuestions.current.findIndex(
      ({ id }) => id === question.id
    )
    allQuestions.current[editedIndex] = question
  }

  const onAddQuestion = (path: Path) => {
    setSelectedPath(path)
    setSelectedQuestion({
      id: (allQuestions.current[allQuestions.current.length - 1]?.id || 0) + 1,
      is_required: true,
    })
  }

  const onEditQuestion = (question: Question, path: Path) => {
    setEditing(true)
    setSelectedPath(path)
    setSelectedQuestion(question)
  }

  const onDeleteQuestion = (question: Question, path: Path) => {
    setPaths(
      paths.map((p) => {
        if (p.id === path.id) {
          return {
            ...p,
            questionObjects: p.questionObjects.filter(
              (q) => q.id !== question.id
            ),
            questions: p.questions.filter((id) => id !== question.id),
          }
        }
        return p
      })
    )
    allQuestions.current = allQuestions.current.filter(
      (q) => q.id !== question.id
    )
  }

  const onAddPath = () => {
    setPaths([
      ...paths,
      {
        id: paths[paths.length - 1]?.id + 1 || 0,
        questions: [],
        questionObjects: [],
      },
    ])
  }

  return (
    <div className='flex row treatmentContainer'>
      <SurveySidebar
        selectedView={calendarView}
        changeView={setCalendarView}
        name={name}
        onNameChange={onNameChange}
      />
      <div className='flex column newTreatmentForm flexAuto dashboardOverflowContainer dashboardContent'>
        <Header
          title={`Survey`}
          description={`You are now creating a survey`}
          action={
            <button className='btnGreen btn' onClick={() => saveSurvey()}>
              {'Save'}
            </button>
          }
        />
        <div className='flex row newTreatmentForm'>
          <div className='flex column flex-grid-12 last-flex'>
            {paths.length > 0 ? (
              <div className='flex column newTreatmentSchedule'>
                <div className='flex column newTreatmentScheduleDay'>
                  <div className='flex column newTreatmentDailyList'>
                    <ul>
                      {paths.map((path) => (
                        <SurveyPath
                          key={`path_${path.id}`}
                          path={path}
                          onAddQuestion={onAddQuestion}
                          onDeleteQuestion={onDeleteQuestion}
                          onEditQuestion={onEditQuestion}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex column flex-grid-12 last-flex newTreatmentSchedule'>
                <div className='flex column flexAuto listItemWrapper emptyItem'>
                  <h4>No Paths</h4>
                  <p>There are no paths of questions in this survey yet</p>
                </div>
              </div>
            )}
            <div className='flex row addADay' onClick={onAddPath}>
              <div className='flex column addIcon justify-center'>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className='flex column justify-center'>
                <h4>Create a Path</h4>
                <p>Create a set of Questions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedQuestion && (
        <QuestionModal
          selectedQuestion={selectedQuestion}
          selectedPath={selectedPath}
          isEditing={isEditing}
          onClose={() => {
            setSelectedQuestion(null)
            setSelectedPath(null)
            setEditing(false)
          }}
          allPaths={paths}
          saveQuestion={saveQuestion}
          editQuestion={editQuestion}
        />
      )}
      {loading && (
        <div className='loadingWrapper'>
          <ReactLoading type='bars' color='#ffffff' />
        </div>
      )}
    </div>
  )
}

export default NewSurvey
