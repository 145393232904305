import React, { FC, useState } from 'react'
import Input from 'components/Input'
import ReactLoading from 'react-loading'
import jsonwebtoken from 'jsonwebtoken'
import { useApi } from 'hooks/useApi'
import { useToast } from 'hooks/useToast'
import { useDispatch } from 'react-redux'
import { setUser } from 'ducks/user'
import User from 'models/User'

import { ReactComponent as LogoSmall } from 'assets/img/humanitas/logoSmall.svg'
import { ReactComponent as Logo } from 'assets/img/humanitas/logo.svg'
import './login.scss'

const Login: FC = () => {
  const { showToast } = useToast()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [loginRequest, { loading }] = useApi('POST', '/login')

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!email || !password) {
      showToast({
        name: 'Error',
        value: 'We need both your email and password',
      })
      return
    }

    const res = await loginRequest({ body: { email, password } })
    const user: User = res?.data
    if (!user) return
    if (!(jsonwebtoken.decode(user?.jwt) as any).isDoctor) {
      showToast({
        name: 'Error',
        value: 'Contact your doctor to access the app',
      })
      return
    }

    localStorage.setItem('user', JSON.stringify(user))
    dispatch(setUser(user))
  }

  return (
    <div className='flex row containerFluid authWrapper'>
      <div className='flex column flex-grid-6 justify-center align-center authBanner'>
        <div className='flex column flex-grid-10 last-flex'>
          <div className='flex row logoWrapper'>
            <Logo />
          </div>
          <h1>Welcome to </h1>
          <h1>Humanitas Percorsi</h1>
          <p>Your patient management system</p>
        </div>
      </div>
      <div className='flex column flex-grid-6 last-flex justify-center align-center mainBg'>
        <div className='flex column flex-grid-10 last-flex'>
          <div className='flex row logoWrapper'>
            <LogoSmall />
          </div>
          <h3>Sign In</h3>
          <p>Sign in to manage your content now!</p>
          <form method='post' className='flex column' onSubmit={onSubmit}>
            <div className='flex column whitebox'>
              <Input
                type='text'
                value={email}
                onChange={onChangeEmail}
                name='loginEmail'
                label='Your Email'
                placeholder='email@some.com'
              />
              <Input
                type='password'
                name='loginPassword'
                value={password}
                helper={<a>Forgot password?</a>}
                onChange={onChangePassword}
                label='Your Password'
                placeholder='*****'
              />
            </div>
            <input
              type='submit'
              className='btn btnGreen align-self-end'
              value='Sign In'
              onClick={onSubmit}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
