import React, { FC, InputHTMLAttributes } from 'react'
import 'assets/styles/input.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  helper?: React.ReactNode
  error?: string
}

const Input: FC<Props> = ({ label, error, helper, name, ...rest }) => {
  return (
    <div
      className={`flex column inputWrapper${error ? ' inputErrorWrapper' : ''}`}
    >
      <div className='flex row'>
        {label && <label htmlFor={name}>{label}</label>}
        {helper && (
          <div className='flex column flexAuto align-end'>{helper}</div>
        )}
      </div>
      <input name={name} {...rest} />
      <span className='inputError'>{error || ''}</span>
    </div>
  )
}

export default Input
