import { useSelector, useDispatch } from 'react-redux'
import { getAllSurveys, setSurveys } from 'ducks/surveys'
import { useApi } from './useApi'

export const useSurveys = () => {
  const dispatch = useDispatch()
  const [getSurveysRequest, { loading }] = useApi('GET', '/doctor/surveys')

  const fetchSurveys = async (params?) => {
    const res = await getSurveysRequest({params})
    dispatch(setSurveys(res?.data?.doctorsSurveys))
  }

  return {
    surveys: useSelector(getAllSurveys),
    fetchSurveys,
    loading,
  }
}
