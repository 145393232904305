import React, { FC, useEffect, useState, useCallback } from 'react'
import Papa from 'papaparse'
import fileDownload from 'react-file-download'
import ReactLoading from 'react-loading'
import { useSurveys } from 'hooks/useSurveys'
import SurveyBox from './components/SurveyBox'
import ListUtilsBar from 'components/ListUtilsBar'
import Header from 'components/Header'
import Survey from 'models/Survey'
import config from 'api/config'
import { useToast } from 'hooks/useToast'
import { useUser } from 'hooks/useUser'
import { debounce } from 'lodash'
import { ReactComponent as Empty } from 'assets/img/humanitas/empty.svg'
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'

import { useHistory, useRouteMatch } from 'react-router-dom'

const orderOptions = [
  { value: 'name,asc', name: 'Survey Name Ascending' },
  { value: 'name,desc', name: 'Survey Name Descending' },
  { value: 'created_at,asc', name: 'Date Created Ascending' },
  { value: 'created_at,desc', name: 'Date Created Descending' },
]
const Surveys: FC = () => {
  const { surveys, loading, fetchSurveys } = useSurveys()
  const history = useHistory()
  let { url } = useRouteMatch()
  const [order, setOrder] = useState('created_at,asc')
  const [search, setSearch] = useState('')
  const [loadingCSV, setLoadingCSV] = useState(false)
  const { showToast } = useToast()
  const user = useUser()
  const allParams = {
    order_by: order,
    search_by: search.length > 2 ? search : '',
  }

  const debauncedFetch = useCallback(
    debounce((params) => {
      fetchSurveys(params)
    }, 600),
    [fetchSurveys]
  )
  const exportCSV = (survey: Survey) => {
    setLoadingCSV(true)
    Papa.parse<any>(`${config.api_host}/doctor/survey/${survey.id}`, {
      download: true,
      downloadRequestHeaders: {
        Authorization: `Bearer ${user.jwt}`,
      },
      error: () => {
        showToast({
          name: 'Error',
          value: 'There is no data yet or something went wrong',
        })
        setLoadingCSV(false)
      },
      complete: (results) => {
        fileDownload(Papa.unparse(results.data), `survey_${survey.id}.csv`)
        setLoadingCSV(false)
      },
    })
  }

  useDidUpdateEffect(() => {
    debauncedFetch(allParams)
  }, [search])
  useEffect(() => {
    fetchSurveys(allParams)
  }, [order])

  return (
    <div className='flex column dashboardContent'>
      <Header
        title='Surveys'
        description={
          surveys ? `${surveys.length} Surveys Available` : 'Loading Surveys...'
        }
      />
      <ListUtilsBar
        pageType='Survey'
        possibleOrders={orderOptions}
        order={order}
        setOrder={setOrder}
        search={search}
        searchFor={setSearch}
        action={() => history.push(`${url}/newSurvey`)}
      />
      <div className='flex row flexWrap treatmentsRow'>
        {surveys && surveys.length > 0 ? (
          surveys.map((s) => <SurveyBox survey={s} exportCSV={exportCSV} />)
        ) : (
          <div className='flex column flex-grid-12 last-flex'>
            <div className='flex column whitebox flexAuto align-center emptyBox'>
              <Empty />
              <h5>
                No Surveys
                {search.length > 3 ? ` containing "${search}"` : null}
              </h5>
              <p>
                <small>
                  {`There are no surveys ${
                    search.length < 3
                      ? 'yet. Feel free to create one in order to get started.'
                      : 'for this search.'
                  }`}
                </small>
              </p>
            </div>
          </div>
        )}
      </div>
      {(loading || loadingCSV) && (
        <div className='loadingWrapper'>
          <ReactLoading type='bars' color='#ffffff' />
        </div>
      )}
    </div>
  )
}

export default Surveys
